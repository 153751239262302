module services {
    export module master {
        export class fileTemplateService implements interfaces.master.IFileTemplateService {
            static $inject = ["$resource", "ENV","$http", "generalService", "$timeout"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig, private $http: ng.IHttpService, public generalService: interfaces.applicationcore.IGeneralService,
                private $timeout: ng.ITimeoutService) {
            }
            
            getList(): ng.resource.IResourceClass<interfaces.master.IFileTemplateDisplay> {

                return this.$resource<interfaces.master.IFileTemplateDisplay>(this.ENV.DSP_URL + "FileTemplate/GetList", {
                });
            }

            getColumnMapping(transactionType: number): ng.resource.IResourceClass<interfaces.master.ColumnMapping> {
                return this.$resource<interfaces.master.ColumnMapping>(this.ENV.DSP_URL + "FileTemplate/GetColumnMapping", {
                    transactionType: transactionType
                });
            }

            getDropDownList(ownerEntityId: number, transactionType: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "FileTemplate/GetDropdownList", {
                    ownerEntityId: ownerEntityId,
                    transactionType: transactionType
                });
            }

            getDetail(fileTemplateId: number): ng.resource.IResourceClass<interfaces.master.IFileTemplateViewModel> {
                return this.$resource<interfaces.master.IFileTemplateViewModel>(this.ENV.DSP_URL + "FileTemplate/GetDetail", {
                    fileTemplateId: fileTemplateId
                }, {
                    query: {
                        method: 'GET',
                        isArray: false,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                    }
                });
            }

        
            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "FileTemplate/Save");
            }


            getFileTemplateUploadAuditLogs(referenceId: number,transactionTypeClassificationValueId:number): ng.resource.IResourceClass<interfaces.master.FileTemplateUploadAuditLog> {
                return this.$resource<interfaces.master.FileTemplateUploadAuditLog>(this.ENV.DSP_URL + "FileTemplate/GetFileTemplateUploadAuditLogs", {
                    referenceId: referenceId,
                    transactionTypeClassificationValueId: transactionTypeClassificationValueId
                }, {
                    query: {
                        method: 'GET',
                        isArray: true,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                    }
                });
            }

            getFileTemplateUploadAuditLogFileById(uplId: number): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'FileTemplate/GetFileTemplateUploadAuditLogFileById?uplId=' + uplId;

                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var contentDisposition = response.headers()['content-disposition'];
                    var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim() || 'FileTemplateFile.xlsx';

                   // var filename = response.headers()['x-filename'] || 'GTSInvoiceDetailDownload.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }   
          

        }
    }
    angular.module("app").service("fileTemplateService", services.master.fileTemplateService);
}